// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.map-marker-label {
    color: white;
    font-size: 1rem;
    margin-bottom: 15px;
    padding-left: 10px;
}

.gm-style-iw-chr {
    display: none;
}

.gm-map-marker-label {
    height: 5.5rem;
    width: 3.438rem;
    display: flex;
    justify-content: flex-start;
}

.gm-map-marker-label[style*="color: white"] {
    text-shadow:
        -1px -1px 0 #191a1c,
        1px -1px 0 #191a1c,
        -1px 1px 0 #191a1c,
        1px 1px 0 #191a1c;
}
`, "",{"version":3,"sources":["webpack://./src/components/GoogleMaps/GoogleMaps.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,eAAe;IACf,mBAAmB;IACnB,kBAAkB;AACtB;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,cAAc;IACd,eAAe;IACf,aAAa;IACb,2BAA2B;AAC/B;;AAEA;IACI;;;;yBAIqB;AACzB","sourcesContent":[".map-marker-label {\n    color: white;\n    font-size: 1rem;\n    margin-bottom: 15px;\n    padding-left: 10px;\n}\n\n.gm-style-iw-chr {\n    display: none;\n}\n\n.gm-map-marker-label {\n    height: 5.5rem;\n    width: 3.438rem;\n    display: flex;\n    justify-content: flex-start;\n}\n\n.gm-map-marker-label[style*=\"color: white\"] {\n    text-shadow:\n        -1px -1px 0 #191a1c,\n        1px -1px 0 #191a1c,\n        -1px 1px 0 #191a1c,\n        1px 1px 0 #191a1c;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
