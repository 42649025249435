import { jsx as _jsx } from "react/jsx-runtime";
import Plotly from "plotly.js-dist-min";
import { useEffect, useRef } from "react";
export const BarChart = ({ autosize, config, data, layout, sliders, style }) => {
    const chartRef = useRef(null);
    const updatedLayout = {
        ...layout,
        sliders: sliders || []
    };
    useEffect(() => {
        if (chartRef.current) {
            Plotly.newPlot(chartRef.current, data, { ...updatedLayout, autosize }, config);
        }
    }, [data, layout, config, autosize]);
    return _jsx("div", { ref: chartRef, style: style });
};
