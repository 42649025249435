import { jsx as _jsx } from "react/jsx-runtime";
import Plotly from "plotly.js-dist-min";
import { useEffect, useRef } from "react";
export const PieChart = (props) => {
    const data = [
        {
            labels: props.labels,
            marker: {
                colors: props.colors
            },
            textinfo: "none",
            type: "pie",
            values: props.values
        }
    ];
    const chartRef = useRef(null);
    const config = { staticPlot: true };
    const layout = {
        height: props.layout.height,
        legend: {
            orientation: "h",
            x: 0.5,
            xanchor: "center",
            y: -0.2,
            yanchor: "top"
        },
        margin: { b: 20, l: 0, r: 0, t: 10 },
        showlegend: true,
        width: props.layout.width
    };
    useEffect(() => {
        if (chartRef.current) {
            Plotly.newPlot(chartRef.current, data, layout, config);
        }
    }, []);
    return _jsx("div", { ref: chartRef });
};
